import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

const AlertMessage = (props) => {
  let { message, type = 'error', showIcon, description, closable } = props;
  const errorDescription = 'HTTP Error 5XX The Service is unavailable.';
  if (type === 'error') {
    description = description || errorDescription;
  }

  return (
    <Alert
      {...props}
      style={{ fontWeight: 500 }}
      message={message || 'Service Unavailable'}
      description={description || ''}
      type={type || 'error'}
      showIcon={showIcon || true}
      closable={closable || false}
    />
  );
};

AlertMessage.propTypes = {
  description: PropTypes.string,
  message: PropTypes.string,
  showIcon: PropTypes.bool,
  closable: PropTypes.bool,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  className: PropTypes.string,
};
export default AlertMessage;
