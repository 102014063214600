// import actions from "./actions";

const initState = {};

export default function toasterReducer(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
