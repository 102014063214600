import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const supplierRoutes = [
  {
    trained: true,
    path: 'pdf-conversion',
    component: asyncComponent(() => import('../PDFConversion')),
  },
  {
    trained: false,
    path: 'pdf-training',
    component: asyncComponent(() => import('../PDFTraining')),
  },
  {
    trained: false,
    path: 'empty-page',
    component: asyncComponent(() => import('../EmptyPage')),
  },
];
const adminRoutes = [
  {
    trained: false,
    path: 'add-supplier',
    component: asyncComponent(() => import('../Admin/CreateSupplier')),
  },
  {
    trained: false,
    path: 'suppliers',
    component: asyncComponent(() => import('../Admin/ShowSuppliers')),
  },
  {
    trained: false,
    path: 'mapping-requests/assigned',
    component: asyncComponent(() => import('../Admin/TrainingRequests/myAssignedRequests')),
  },
  {
    trained: false,
    path: 'mapping-requests/pending',
    component: asyncComponent(() => import('../Admin/TrainingRequests/showPendingRequests')),
  },
  {
    trained: false,
    path: 'mapping-requests',
    component: asyncComponent(() => import('../Admin/TrainingRequests/showAllRequests')),
  },
  {
    trained: false,
    path: 'trainers',
    component: asyncComponent(() => import('../Admin/UserManagement/ShowTrainers')),
  },
  {
    trained: false,
    path: 'add-trainer',
    component: asyncComponent(() => import('../Admin/UserManagement/AddTrainer')),
  },
  {
    trained: false,
    path: 'mapping-request/:id',
    component: asyncComponent(() => import('../Admin/TrainingRequests/trainingReqDetails')),
  },
];
const trainerRoutes = [
  {
    trained: false,
    path: 'add-supplier',
    component: asyncComponent(() => import('../Admin/CreateSupplier')),
  },
  {
    trained: false,
    path: 'suppliers',
    component: asyncComponent(() => import('../Admin/ShowSuppliers')),
  },
  {
    trained: false,
    path: 'mapping-request/:id',
    component: asyncComponent(() => import('../Admin/TrainingRequests/trainingReqDetails')),
  },
  {
    trained: false,
    path: 'mapping-requests/pending',
    component: asyncComponent(() => import('../Admin/TrainingRequests/showPendingRequests')),
  },
  {
    trained: false,
    path: 'mapping-requests/assigned',
    component: asyncComponent(() => import('../Admin/TrainingRequests/myAssignedRequests')),
  },
  {
    trained: false,
    path: 'mapping-requests',
    component: asyncComponent(() => import('../Admin/TrainingRequests/showAllRequests')),
  },
];
const AppRouter = (props) => {
  const { url, style } = props;
  const { isTrained, role } = useSelector(({ Auth }) => Auth.user);

  let routes = [];
  if (role === 'supplier') routes = supplierRoutes;
  else if (role === 'admin') routes = adminRoutes;
  else if (role === 'trainer') routes = trainerRoutes;

  return (
    <div style={style}>
      {routes.map((singleRoute) => {
        const { path, exact, ...otherProps } = singleRoute;
        return (
          // <Route
          //   exact={exact === false ? false : true}
          //   key={singleRoute.path}
          //   path={`${url}/${singleRoute.path}`}
          //   {...otherProps}
          // />
          (role === 'admin' ||
            role === 'trainer' ||
            isTrained ||
            isTrained === singleRoute.trained) && (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          )
        );
      })}
    </div>
  );
};

export default AppRouter;
// class AppRouter extends Component {
//   render() {
//     const { url, style } = this.props;
//     return (
//       <div style={style}>
//         {routes.map((singleRoute) => {
//           const { path, exact, ...otherProps } = singleRoute;
//           return (
//             <Route
//               exact={exact === false ? false : true}
//               key={singleRoute.path}
//               path={`${url}/${singleRoute.path}`}
//               {...otherProps}
//             />
//           );
//         })}
//       </div>
//     );
//   }
// }

// export default AppRouter;
