import { axios, getAxiosError } from '../apiService';

const actions = {
  // Add trained supplier
  ADD_TRAINED_SUPPLIER_PENDING: 'ADD_TRAINED_SUPPLIER_PENDING',
  ADD_TRAINED_SUPPLIER_SUCCESS: 'ADD_TRAINED_SUPPLIER_SUCCESS',
  ADD_TRAINED_SUPPLIER_FAILURE: 'ADD_TRAINED_SUPPLIER_FAILURE',

  // Get all suppliers
  GET_ALL_SUPPLIERS_PENDING: 'GET_ALL_SUPPLIERS_PENDING',
  GET_ALL_SUPPLIERS_SUCCESS: 'GET_ALL_SUPPLIERS_SUCCESS',
  GET_ALL_SUPPLIERS_FAILURE: 'GET_ALL_SUPPLIERS_FAILURE',

  // Delete Supplier
  DELETE_SUPPLIER_PENDING: 'DELETE_SUPPLIER_PENDING',
  DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
  DELETE_SUPPLIER_FAILURE: 'DELETE_SUPPLIER_FAILURE',

  createSupplier: (values) => async (dispatch) => {
    try {
      dispatch({ type: actions.ADD_TRAINED_SUPPLIER_PENDING });
      const res = await axios.post(`supplier/`, values);
      dispatch({ type: actions.ADD_TRAINED_SUPPLIER_SUCCESS, payload: res.data?.message });
    } catch (error) {
      dispatch({ type: actions.ADD_TRAINED_SUPPLIER_FAILURE, payload: getAxiosError(error) });
    }
  },
  getSuppliers: () => async (dispatch) => {
    try {
      dispatch({ type: actions.GET_ALL_SUPPLIERS_PENDING });
      const res = await axios.get(`supplier/`);
      dispatch({ type: actions.GET_ALL_SUPPLIERS_SUCCESS, payload: res.data?.suppliers });
    } catch (error) {
      dispatch({ type: actions.GET_ALL_SUPPLIERS_FAILURE, payload: getAxiosError(error) });
    }
  },
  deleteSupplier: (supplierId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actions.DELETE_SUPPLIER_PENDING });
        const res = await axios.delete(`supplier/${supplierId}`);
        dispatch({ type: actions.DELETE_SUPPLIER_SUCCESS, payload: res.data.data });
        resolve(true);
      } catch (error) {
        dispatch({ type: actions.DELETE_SUPPLIER_FAILURE, payload: getAxiosError(error) });
        reject(error);
      }
    });
  },
};
export default actions;
