import history from './history';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

class Auth0Helper {
  TOKEN_TYPE = 'Bearer';
  AUTH_TOKEN = 'authToken';
  REFRESH_TOKEN = 'refreshToken';
  AUTHORIZATION_HEADER = 'Authorization';

  constructor() {
    this.logout = this.logout.bind(this);
    this.isTokenExpired = this.isTokenExpired.bind(this);
  }
  getAuthToken() {
    return localStorage.getItem(this.AUTH_TOKEN);
  }
  setAuthToken(token) {
    localStorage.setItem(this.AUTH_TOKEN, token);
  }
  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }
  setRefreshToken(token) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  logout() {
    this.setAuthTokenInHeader(false);
    this.removeAllTokens();
    history.push('/');
  }

  removeAllTokens() {
    localStorage.removeItem(this.AUTH_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  setAuthTokenInHeader = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `${this.TOKEN_TYPE} ${token}`;
    } else {
      delete axios.defaults.headers.common[this.AUTHORIZATION_HEADER];
    }
  };
  getDecodedAuthToken() {
    const authToken = this.getAuthToken();
    if (authToken) {
      return jwt_decode(authToken);
    } else {
      return null;
    }
  }

  isTokenExpired() {
    const decoded = this.getDecodedAuthToken();
    if (decoded) {
      const expireTime = decoded.exp;
      const currentTime = Date.now() / 1000;
      return expireTime < currentTime;
    } else {
      return true;
    }
  }
}
export default new Auth0Helper();
