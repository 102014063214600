import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PublicRoutes = ({ history }) => {
  const isAuthenticated = useSelector(({ Auth }) => Auth.isAuthenticated);

  return (
    <Router>
      <Switch>
        <Route exact path={'/'} component={asyncComponent(() => import('./containers/Signin'))} />
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
        <Route
          exact
          path={'/cargox-verification'}
          component={asyncComponent(() => import('./containers/AuthWithCargox'))}
        />
        <Route
          exact
          path={'/500'}
          component={asyncComponent(() => import('./containers/Page/500'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Signin'))}
        />
        <Route
          exact
          path={'/admin/login'}
          component={asyncComponent(() => import('./containers/Admin/Signin'))}
        />

        <RestrictedRoute
          path="/dashboard"
          component={App}
          // component={asyncComponent(() =>
          //   import("./containers/Page/resetPassword")
          // )}
          isLoggedIn={isAuthenticated}
        />
        <Route component={asyncComponent(() => import('./containers/Page/404'))} />
      </Switch>
    </Router>
  );
};
export default PublicRoutes;
