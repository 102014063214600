import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import Auth0Helper from '../../helpers/auth0/index';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import AppLocale from '../../languageProvider';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import RefreshTokenModal from '../../components/common/refreshTokenModal';
import './global.css';
import uipathImg from '../../assets/images/uipath-icon.svg';
import socketIOClient from 'socket.io-client';
import { dotenvConfig } from '../../assets/static/dotenv';

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
const { Content, Footer } = Layout;
const { logout, updateUserIsTrained } = authAction;
const { toggleAll } = appActions;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.showModal = this.showModal.bind(this);
  }
  componentDidMount() {
    let socket = socketIOClient('https://portal.bizdocconvert.com');
       socket.on('doc:update-user', (data) => {
         if(data === this.props.auth.user?.supplierCargoxId){
          this.props.updateUserIsTrained();
         }
    });
    setInterval(() => {
      const isTokenExpired = Auth0Helper.isTokenExpired();
      if (isTokenExpired) this.showModal();
    }, 5000); // 5 Seconds
  }
  showModal = () => {
    this.setState({ visible: true });
  };
  hideModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;

    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight)
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />

                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '70px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                      <RefreshTokenModal visible={this.state.visible} hideModal={this.hideModal} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed',
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>{siteConfig.footerText}</span>
                      <img src={uipathImg} width="60px" height="30px" alt='uipath' />
                    </Footer>
                    {/* <ToastContainer autoClose={4000} hideProgressBar={true} /> */}
                  </Layout>
                </Layout>
                {/* <ThemeSwitcher /> */}
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.height,
  }),
  { logout, toggleAll, updateUserIsTrained }
)(App);
