// import getDevSidebar from "../../customApp/sidebar";
import { userRoles } from '../../helpers/types';
const options = [
  // {
  //   key: '',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'far fa-chart-bar',
  //   text: 'Dashboard',
  // },
  // {
  //   key: "clients",
  //   text: "Clients",
  //   label: "sidebar.Clients",
  //   leftIcon: "fas fa-users",
  //   children: [
  //     {
  //       key: "clients",
  //       label: "sidebar.viewClients",
  //       text: "View Clients"
  //     },
  //     {
  //       key: "clients/new",
  //       label: "sidebar.addNewClient",
  //       text: "Add New Client"
  //     }
  //   ]
  // }

  // {
  //   key: 'new',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'fas fa-plus',
  //   text: 'Add New Document',
  // },
  // {
  //   key: 'company',
  //   text: 'Receivers',
  //   label: 'sidebar.Clients',
  //   leftIcon: 'fas fa-building',
  //   children: [
  //     {
  //       key: 'company/new',
  //       label: 'sidebar.Dashboard',
  //       leftIcon: 'fas fa-building',
  //       text: 'Add New Receiver',
  //     },
  //   ],
  // },
  // {
  //   key: 'company/new',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'fas fa-building',
  //   text: 'Add New Receiver',
  // },
  {
    allowed: [userRoles.supplier],
    key: 'pdf-conversion',
    label: 'sidebar.Dashboard',
    leftIcon: 'fas fa-file-pdf',
    text: 'PDF Conversion',
    trained: true,
  },

  {
    allowed: [userRoles.supplier],
    key: 'pdf-training',
    label: 'sidebar.Dashboard',
    leftIcon: 'fas fa-file-pdf',
    text: 'PDF Training',
    trained: false,
  },
  {
    allowed: [userRoles.admin, userRoles.trainer],
    key: 'add-supplier',
    label: 'sidebar.Dashboard',
    leftIcon: 'fas fa-building',
    text: 'Add Supplier',
  },
  {
    allowed: [userRoles.admin, userRoles.trainer],
    key: 'suppliers',
    label: 'sidebar.Dashboard',
    leftIcon: 'fas fa-building',
    text: 'Show Suppliers',
  },
  // {
  //   allowed: [userRoles.admin],
  //   key: 'pending-requests',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'fas fa-building',
  //   text: 'Pending Training Requests',
  // },

  // {
  //   allowed: [userRoles.admin],
  //   key: 'pending-requests',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'fas fa-building',
  //   text: 'Pending Training Requests',
  // },

  // {
  //   key: 'setting',
  //   label: 'sidebar.Dashboard',
  //   leftIcon: 'fas fa-cog',
  //   text: 'Settings',
  // },

  {
    allowed: [userRoles.admin, userRoles.trainer],
    key: 'mapping-requests',
    text: 'Mapping Requests',
    label: 'sidebar.Clients',
    leftIcon: 'fas fa-th-list',
    children: [
      {
        allowed: [userRoles.trainer, userRoles.admin],
        key: 'mapping-requests/assigned',
        label: 'sidebar.viewClients',
        text: 'My assigned REQS.',
      },
      {
        allowed: [userRoles.admin, userRoles.trainer],
        key: 'mapping-requests/pending',
        label: 'sidebar.viewClients',
        text: 'Pending Requests',
      },
      {
        allowed: [userRoles.admin, userRoles.trainer],
        key: 'mapping-requests',
        label: 'sidebar.addNewClient',
        text: 'All Requests',
      },
    ],
  },

  {
    allowed: [userRoles.admin],
    key: 'userManagement',
    text: 'Users Management',
    label: 'sidebar.Clients',
    leftIcon: 'fas fa-users',
    children: [
      {
        allowed: [userRoles.admin],
        key: 'add-trainer',
        label: 'sidebar.viewClients',
        text: 'Add Trainer',
      },
      {
        allowed: [userRoles.admin],
        key: 'trainers',
        label: 'sidebar.addNewClient',
        text: 'Show Trainers',
      },
    ],
  },
];

export default options;
