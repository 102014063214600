import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import reducers from '../redux/reducers';
import { dotenvConfig } from '../assets/static/dotenv';

const history = createBrowserHistory();

const middlewares = [thunk];

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  dotenvConfig.appEnv === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const store = createStore(
  combineReducers({ ...reducers }),
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store, history };
