import { axios, getAxiosError } from '../apiService';

const actions = {
  // Get trainers
  GET_TRAINERS_PENDING: 'GET_TRAINERS_PENDING',
  GET_TRAINERS_SUCCESS: 'GET_TRAINERS_SUCCESS',
  GET_TRAINERS_FAILURE: 'GET_TRAINERS_FAILURE',

  // Create trainer
  CREATE_TRAINER_PENDING: 'CREATE_TRAINER_PENDING',
  CREATE_TRAINER_SUCCESS: 'CREATE_TRAINER_SUCCESS',
  CREATE_TRAINER_FAILURE: 'CREATE_TRAINER_FAILURE',

  // Create trainer
  FLUSH_CREATE_TRAINER: 'FLUSH_CREATE_TRAINER',

  getTrainers: () => async (dispatch) => {
    try {
      dispatch({ type: actions.GET_TRAINERS_PENDING });
      const res = await axios.get(`user/trainer`);
      dispatch({
        type: actions.GET_TRAINERS_SUCCESS,
        payload: { message: res.data?.message, data: res.data?.data },
      });
    } catch (error) {
      dispatch({ type: actions.GET_TRAINERS_FAILURE, payload: getAxiosError(error) });
    }
  },

  createTrainer: (values) => async (dispatch) => {
    try {
      dispatch({ type: actions.CREATE_TRAINER_PENDING });
      const res = await axios.post(`user/trainer`, values);
      dispatch({
        type: actions.CREATE_TRAINER_SUCCESS,
        payload: { message: res.data?.message, data: res.data?.data },
      });
    } catch (error) {
      dispatch({ type: actions.CREATE_TRAINER_FAILURE, payload: getAxiosError(error) });
    }
  },
  flushCreateTrainer: () => async (dispatch) => {
    dispatch({ type: actions.FLUSH_CREATE_TRAINER });
  },
};
export default actions;
