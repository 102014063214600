import { axios, getAxiosError } from '../apiService';
import Axios from 'axios';
import Auth0Helper from '../../helpers/auth0/index';
import { dotenvConfig } from '../../assets/static/dotenv';
import appActions from '../app/actions';
const { changeCurrent } = appActions;

const actions = {
  // Connect CargoX
  CONNECT_CARGOX_PENDING: 'CONNECT_CARGOX_PENDING',
  CONNECT_CARGOX_SUCCESS: 'CONNECT_CARGOX_SUCCESS',
  CONNECT_CARGOX_FAILURE: 'CONNECT_CARGOX_FAILURE',
  // Login with CargoX
  LOGIN_WITH_CARGOX_PENDING: 'LOGIN_WITH_CARGOX_PENDING',
  LOGIN_WITH_CARGOX_SUCCESS: 'LOGIN_WITH_CARGOX_SUCCESS',
  LOGIN_WITH_CARGOX_FAILURE: 'LOGIN_WITH_CARGOX_FAILURE',
  // Check Authorization
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  // Logout
  LOGOUT: 'LOGOUT',
  // Refresh Token
  REFRESH_TOKEN_PENDING: 'REFRESH_TOKEN_PENDING',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  // Get CargoX Code from search
  GET_CARGOX_CODE: 'GET_CARGOX_CODE',
  // Refresh Token
  LOCAL_SIGNIN_PENDING: 'LOCAL_SIGNIN_PENDING',
  LOCAL_SIGNIN_SUCCESS: 'LOCAL_SIGNIN_SUCCESS',
  LOCAL_SIGNIN_FAILURE: 'LOCAL_SIGNIN_FAILURE',
  UPDATE_USER: 'UPDATE_USER',

  connectToCargox: () => async (dispatch) => {
    try {
      dispatch({ type: actions.CONNECT_CARGOX_PENDING });
      const res = await axios.post(`cargox/connect`);
      dispatch({ type: actions.CONNECT_CARGOX_SUCCESS, payload: res.data.cargox_url });
    } catch (error) {
      dispatch({ type: actions.CONNECT_CARGOX_FAILURE, payload: actions.getErrorMsg(error) });
    }
  },
  loginWithCarogx: (accessCode) => async (dispatch) => {
    try {
      dispatch({ type: actions.LOGIN_WITH_CARGOX_PENDING });
      const res = await axios.post(`cargox/login`, { accessCode });
      const { authToken, refreshToken } = res.data;
      Auth0Helper.setAuthToken(authToken);
      Auth0Helper.setRefreshToken(refreshToken);
      const decoded = Auth0Helper.getDecodedAuthToken();
      dispatch({ type: actions.LOGIN_WITH_CARGOX_SUCCESS, payload: decoded });
    } catch (error) {
      dispatch({ type: actions.LOGIN_WITH_CARGOX_FAILURE, payload: actions.getErrorMsg(error) });
    }
  },
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  logout: () => (dispatch) => {
    Auth0Helper.logout();
    dispatch({ type: actions.LOGOUT });
  },
  setCargoxCode: (code) => (dispatch) => {
    dispatch({ type: actions.GET_CARGOX_CODE, payload: code });
  },
  getErrorMsg: (error) => {
    return error?.response?.data?.message || 'Service Unavailable';
  },
  refreshToken: () => async (dispatch) => {
    try {
      dispatch({ type: actions.REFRESH_TOKEN_PENDING });
      const config = {
        headers: { Authorization: `${Auth0Helper.TOKEN_TYPE} ${Auth0Helper.getRefreshToken()}` },
      };

      const res = await Axios.post(`${dotenvConfig.baseURL}auth/refreshToken`, {}, config);
      const { authToken, refreshToken } = res.data;
      Auth0Helper.setAuthToken(authToken);
      Auth0Helper.setRefreshToken(refreshToken);
      const decoded = Auth0Helper.getDecodedAuthToken();
      dispatch({ type: actions.REFRESH_TOKEN_SUCCESS, payload: decoded });
    } catch (error) {
      dispatch({ type: actions.REFRESH_TOKEN_FAILURE, payload: actions.getErrorMsg(error) });
    }
  },
  localSignin: (values) => async (dispatch) => {
    try {
      dispatch({ type: actions.LOCAL_SIGNIN_PENDING });
      const res = await axios.post(`auth/login`, values);
      const { authToken, refreshToken } = res.data;
      Auth0Helper.setAuthToken(authToken);
      Auth0Helper.setRefreshToken(refreshToken);
      const decoded = Auth0Helper.getDecodedAuthToken();
      dispatch({ type: actions.LOCAL_SIGNIN_SUCCESS, payload: decoded });
    } catch (error) {
      dispatch({ type: actions.LOCAL_SIGNIN_FAILURE, payload: getAxiosError(error) });
    }
  },
  updateToken:()=> async (dispatch) => {
    try {
      dispatch({ type: actions.REFRESH_TOKEN_PENDING });
      const config = {
        headers: { Authorization: `${Auth0Helper.TOKEN_TYPE} ${Auth0Helper.getAuthToken()}` },
      };
      const res = await Axios.post(`${dotenvConfig.baseURL}auth/updateToken`, {}, config);
      const { authToken, refreshToken } = res.data;
      Auth0Helper.setAuthToken(authToken);
      Auth0Helper.setRefreshToken(refreshToken);
      const decoded = Auth0Helper.getDecodedAuthToken();
      dispatch({ type: actions.REFRESH_TOKEN_SUCCESS, payload: decoded });
    } catch (error) {
      dispatch({ type: actions.REFRESH_TOKEN_FAILURE, payload: actions.getErrorMsg(error) });
      setTimeout(() => {
        dispatch(actions.logout())
      }, 2000);
    }
  },
  updateUserIsTrained: () => async (dispatch) => {
    dispatch(actions.updateToken())
    dispatch({type: actions.UPDATE_USER})
  }
};
export default actions;
