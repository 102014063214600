import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar.js';
// import Menu from "../../components/uielements/menu";
import { Menu } from 'antd';
// import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import { userRoles } from '../../helpers/types';

const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    this.isAllowed = this.isAllowed.bind(this);
    this.isChildAllowed = this.isChildAllowed.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
    const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, leftIcon, children, text } = singleOption; // label,
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <div
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    // fontWeight: 500,
                    fontSize: '15px',
                  }}
                >
                  {text}
                </div>
                {/* <IntlMessages id={label} /> */}
              </span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
            if (this.isChildAllowed(child)) {
              return (
                <Menu.Item style={submenuStyle} key={child.key}>
                  <Link style={submenuColor} to={linkTo}>
                    <div
                      style={{
                        fontFamily: "'Montserrat', sans-serif",
                        // fontWeight: 500,
                        fontSize: '15px',
                      }}
                    >
                      {child.text}
                      {/* <b>
                      <IntlMessages style={{ color: "red" }} id={child.label} />
                    </b> */}
                    </div>
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <div
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  // fontWeight: 500,
                  fontSize: '15px',
                }}
              >
                {text}
              </div>
              {/* <IntlMessages id={label} /> */}
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  isAllowed = (singleOption) => {
    const user = this.props?.auth?.user;
    if (singleOption.allowed.includes(user.role)) {
      if (user.role === userRoles.supplier) {
        return user.isTrained || user.isTrained === singleOption.trained;
      } else {
        return true;
      }
    }
    return false;
  };
  isChildAllowed = (child) => {
    const user = this.props?.auth?.user;
    return child?.allowed?.includes(user.role);
  };

  render() {
    const { app, toggleOpenDrawer, customizedTheme, height, auth } = this.props;

    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
      fontWeight: 500,
      // backgroundColor: '#031a4c',
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      fontWeight: 500,
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
      fontWeight: 500,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              // theme="dark"
              theme="light"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map(
                (singleOption) =>
                  this.isAllowed(singleOption) &&
                  this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}

              {/* <SubMenu
                key="sub1"
                title={
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-android-options" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.menuLevels" />
                    </span>
                  </span>
                }
              >
                <MenuItemGroup
                  key="g1"
                  title={<IntlMessages id="sidebar.item1" />}
                >
                  <Menu.Item style={submenuStyle} key="1">
                    <IntlMessages id="sidebar.option1" />
                  </Menu.Item>
                  <Menu.Item style={submenuStyle} key="2">
                    <IntlMessages id="sidebar.option2" />
                  </Menu.Item>
                </MenuItemGroup>
                <MenuItemGroup
                  key="g2"
                  title={<IntlMessages id="sidebar.item2" />}
                >
                  <Menu.Item style={submenuStyle} key="3">
                    <IntlMessages id="sidebar.option3" />
                  </Menu.Item>
                  <Menu.Item style={submenuStyle} key="4">
                    <IntlMessages id="sidebar.option4" />
                  </Menu.Item>
                </MenuItemGroup>
              </SubMenu> */}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height,
    auth: state.Auth,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
