import actions from './actions';

const initState = {
  conversion: { isFetching: false, successMsg: null, error: {} },
  trainingForm: {
    activeStep: 0,
    trainingFiles: [],
    mappingFiles: [],
    email: undefined,
  },
  training: { isFetching: false, successMsg: null, error: {} },
  trainingRequests: { isFetching: false, successMsg: null, error: {}, data: [] },
  singleTrainingRequests: { isFetching: true, successMsg: null, error: {}, data: null },
  assignTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
  acceptTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
  rejectTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
};

export default function invoiceReducer(state = initState, action) {
  switch (action.type) {
    case actions.PDF_CONVERSON_PENDING: {
      return {
        ...state,
        conversion: { ...state.conversion, isFetching: true, successMsg: null, error: {} },
      };
    }
    case actions.PDF_CONVERSON_SUCCESS: {
      return {
        ...state,
        conversion: {
          ...state.conversion,
          isFetching: false,
          successMsg: action.payload,
          error: {},
        },
      };
    }
    case actions.PDF_CONVERSON_FAILURE: {
      return {
        ...state,
        conversion: {
          ...state.conversion,
          isFetching: false,
          successMsg: null,
          error: action.payload,
        },
      };
    }

    case actions.TRAINING_FORM_RESET: {
      return {
        ...state,
        trainingForm: { activeStep: 0, trainingFiles: [], mappingFiles: [], email: undefined },
        training: { ...state.training, isFetching: false, successMsg: null, error: {} },
      };
    }
    case actions.TRAINING_FORM_SET_ACTIVE_STEP: {
      return {
        ...state,
        trainingForm: { ...state.trainingForm, activeStep: action.payload },
      };
    }
    case actions.TRAINING_FORM_SET_TRAINING_FILES: {
      return {
        ...state,
        trainingForm: { ...state.trainingForm, trainingFiles: action.payload },
      };
    }
    case actions.TRAINING_FORM_SET_MAPPING_FILES: {
      return {
        ...state,
        trainingForm: { ...state.trainingForm, mappingFiles: action.payload },
      };
    }
    case actions.TRAINING_FORM_SET_EMAIL_ADDR: {
      return {
        ...state,
        trainingForm: { ...state.trainingForm, email: action.payload },
      };
    }
    // PDF Training
    case actions.PDF_TRAINING_PENDING: {
      return {
        ...state,
        training: { ...state.training, isFetching: true, successMsg: null, error: {} },
      };
    }
    case actions.PDF_TRAINING_SUCCESS: {
      return {
        ...state,
        training: {
          ...state.training,
          isFetching: false,
          successMsg: action.payload,
          error: {},
        },
      };
    }
    case actions.PDF_TRAINING_FAILURE: {
      const { rejectedFiles = {} } = action.details;
      let activeStep;
      let errorMsg = action.payload.message;
      if (rejectedFiles.hasOwnProperty('trainingFiles')) {
        activeStep = 0;
        const fileNames = rejectedFiles['trainingFiles'].map((file) => file.originalname);
        errorMsg = `These file(s) are not readable: ${fileNames.join(', ')}`;
      } else if (rejectedFiles.hasOwnProperty('mappingFiles')) {
        activeStep = 1;
        const fileNames = rejectedFiles['mappingFiles'].map((file) => file.originalname);
        errorMsg = `These file(s) are not readable: ${fileNames.join(', ')}`;
      }
      return {
        ...state,
        training: {
          ...state.training,
          isFetching: false,
          successMsg: null,
          error: { ...action.payload, message: errorMsg },
        },
        trainingForm: { ...state.trainingForm, activeStep },
      };
    }

    // Get training requests
    case actions.GET_TRAINING_REQUESTS_PENDING: {
      return {
        ...state,
        trainingRequests: {
          ...state.trainingRequests,
          isFetching: true,
          successMsg: null,
          error: {},
          data: [],
        },
      };
    }
    case actions.GET_TRAINING_REQUESTS_SUCCESS: {
      return {
        ...state,
        trainingRequests: {
          ...state.trainingRequests,
          isFetching: false,
          successMsg: action.payload.message,
          data: action.payload.data,
          error: {},
        },
      };
    }
    case actions.GET_TRAINING_REQUESTS_FAILURE: {
      return {
        ...state,
        trainingRequests: {
          ...state.trainingRequests,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: [],
        },
      };
    }

    // Get single training request
    case actions.GET_SINGLE_TRAINING_REQUEST_PENDING: {
      return {
        ...state,
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: true,
          successMsg: null,
          error: {},
          data: null,
        },
      };
    }
    case actions.GET_SINGLE_TRAINING_REQUESTS_SUCCESS: {
      return {
        ...state,
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: false,
          successMsg: action.payload.message,
          data: action.payload.data,
          error: {},
        },
      };
    }
    case actions.GET_SINGLE_TRAINING_REQUESTS_FAILURE: {
      return {
        ...state,
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: null,
        },
      };
    }

    // Assign Training Request
    case actions.ASSIGN_TRAINING_REQUEST_PENDING: {
      return {
        ...state,
        assignTrainingRequests: {
          ...state.assignTrainingRequests,
          isFetching: true,
          successMsg: null,
          error: {},
          data: null,
        },
      };
    }
    case actions.ASSIGN_TRAINING_REQUESTS_SUCCESS: {
      return {
        ...state,
        assignTrainingRequests: {
          ...state.assignTrainingRequests,
          isFetching: false,
          successMsg: action.payload.message,
          // data: action.payload.data,
          error: {},
        },
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: false,
          successMsg: null,
          data: action.payload.data,
        },
      };
    }
    case actions.ASSIGN_TRAINING_REQUESTS_FAILURE: {
      return {
        ...state,
        assignTrainingRequests: {
          ...state.assignTrainingRequests,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: null,
        },
      };
    }

    // Accept Training Request
    case actions.ACCEPT_TRAINING_REQUEST_PENDING: {
      return {
        ...state,
        acceptTrainingRequests: {
          ...state.acceptTrainingRequests,
          isFetching: true,
          successMsg: null,
          error: {},
          data: null,
        },
      };
    }
    case actions.ACCEPT_TRAINING_REQUESTS_SUCCESS: {
      return {
        ...state,
        acceptTrainingRequests: {
          ...state.acceptTrainingRequests,
          isFetching: false,
          successMsg: action.payload.message,
          // data: action.payload.data,
          error: {},
        },
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: false,
          successMsg: null,
          data: action.payload.data,
        },
      };
    }
    case actions.ACCEPT_TRAINING_REQUESTS_FAILURE: {
      return {
        ...state,
        acceptTrainingRequests: {
          ...state.acceptTrainingRequests,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: null,
        },
      };
    }

    // Reject Training Request
    case actions.REJECT_TRAINING_REQUEST_PENDING: {
      return {
        ...state,
        rejectTrainingRequests: {
          ...state.rejectTrainingRequests,
          isFetching: true,
          successMsg: null,
          error: {},
          data: null,
        },
      };
    }
    case actions.REJECT_TRAINING_REQUESTS_SUCCESS: {
      return {
        ...state,
        rejectTrainingRequests: {
          ...state.rejectTrainingRequests,
          isFetching: false,
          successMsg: action.payload.message,
          // data: action.payload.data,
          error: {},
        },
        singleTrainingRequests: {
          ...state.singleTrainingRequests,
          isFetching: false,
          successMsg: null,
          data: action.payload.data,
        },
      };
    }
    case actions.REJECT_TRAINING_REQUESTS_FAILURE: {
      return {
        ...state,
        rejectTrainingRequests: {
          ...state.rejectTrainingRequests,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: null,
        },
      };
    }

    case actions.FLUSH_TRAINING_REQUEST: {
      return {
        ...state,
        assignTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
        acceptTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
        rejectTrainingRequests: { isFetching: false, successMsg: null, error: {}, data: null },
      };
    }
    case actions.LOGOUT:
      //Set current user to {} which will set isAuthenticated to false
      return initState;

    default:
      return state;
  }
}
