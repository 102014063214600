import actions from './actions';
import { isEmpty } from '../../helpers/utility';
import Auth0Helper from '../../helpers/auth0/index';

const initState = {
  isAuthenticated: false,
  user: {},
  errorMessage: null,
  cargox: {
    code: null,
    // Connect with cargox
    connectWithCargox: {
      cargoxUrl: undefined,
      isFetching: false,
      errorMsg: null,
    },
    // Login with cargox
    loginWithCargox: {
      data: undefined,
      isFetching: false,
      errorMsg: null,
    },
  },
  refreshToken: {
    changed: false,
    isFetching: false,
    errorMsg: null,
  },
  localSignin: { isFetching: false, successMsg: null, error: {} },
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.CONNECT_CARGOX_SUCCESS: {
      return {
        ...state,
        cargox: {
          ...state.cargox,
          connectWithCargox: { ...state.cargox.connectWithCargox, cargoxUrl: action.payload },
        },
      };
    }

    case actions.LOGIN_WITH_CARGOX_SUCCESS: {
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        errorMessage: null,
      };
    }
    case actions.LOGIN_WITH_CARGOX_FAILURE: {
      return {
        ...state,
        cargox: {
          ...state.cargox,
          loginWithCargox: { ...state.cargox.loginWithCargox, errorMsg: action.payload },
        },
      };
    }

    case actions.CHECK_AUTHORIZATION: {
      const authToken = Auth0Helper.getAuthToken();
      if (authToken) {
        const isTokenExpired = Auth0Helper.isTokenExpired();
        if (isTokenExpired) {
          Auth0Helper.logout();
          return initState;
        } else {
          const decoded = Auth0Helper.getDecodedAuthToken();
          return { ...state, isAuthenticated: true, user: decoded };
        }
      } else {
        return initState;
      }
    }
    case actions.REFRESH_TOKEN_PENDING: {
      return {
        ...state,
        refreshToken: { ...state.refreshToken, isFetching: true, changed: false },
      };
    }
    case actions.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        errorMessage: null,
        refreshToken: { ...state.refreshToken, isFetching: false, changed: true },
      };
    }
    case actions.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        refreshToken: {
          ...state.refreshToken,
          isFetching: false,
          errorMsg: action.payload,
          changed: false,
        },
      };
    }
    case actions.GET_CARGOX_CODE: {
      return { ...state, cargox: { ...state.cargox, code: action.payload } };
    }

    case actions.LOCAL_SIGNIN_PENDING: {
      return {
        ...state,
        localSignin: { ...state.localSignin, isFetching: true, successMsg: null, error: {} },
      };
    }
    case actions.LOCAL_SIGNIN_SUCCESS: {
      return {
        ...state,
        localSignin: { ...state.localSignin, isFetching: false, error: {} },
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        errorMessage: null,
      };
    }
    case actions.LOCAL_SIGNIN_FAILURE: {
      return {
        ...state,
        localSignin: {
          ...state.localSignin,
          isFetching: false,
          successMsg: null,
          error: action.payload,
        },
      };
    }

    case actions.LOGOUT: {
      return initState;
    }
    case actions.UPDATE_USER:
      return {
        ...state,
        user : {
          ...state.user,
          isTrained:true
        }
      }

    default:
      return state;
  }
}
