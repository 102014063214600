import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Divider } from 'antd';
// Comps
import timeoutImg from '../../../assets/images/time.svg';
import AlertMessage from '../../common/alertMessage';
// Actions
import authActions from '../../../redux/auth/actions';

const { refreshToken, logout } = authActions;

const RefreshTokenModal = ({ visible, hideModal }) => {
  const dispatch = useDispatch();
  const { changed, isFetching, errorMsg } = useSelector(({ Auth }) => Auth.refreshToken);
  const handleExtendSession = () => {
    dispatch(refreshToken());
  };
  useEffect(() => {
    if (changed) hideModal();
  }, [changed]);

  const handleCancel = () => dispatch(logout());
  return (
    <Modal
      title="Your session has expired"
      visible={visible}
      onOk={hideModal}
      closable={false}
      // onCancel={null}
      okText={'Refresh Session'}
      cancelText="Logout"
      footer={null}
    >
      {errorMsg && <AlertMessage />}
      <div className="row mb-3">
        <img
          src={timeoutImg}
          width="100"
          height="70"
          style={{ display: 'block', margin: 'auto' }}
        />
      </div>

      <div className="row">
        <div className="fw-500 col-12">
          Your session has expired, Do you need to extend your current session?
        </div>
      </div>

      <Divider />
      <div className="row">
        <div className="col-6">
          <Button
            loading={isFetching}
            className="fw-500"
            size="large"
            style={{ backgroundColor: '#2e79ee', color: '#fff' }}
            onClick={handleExtendSession}
          >
            Extend session
          </Button>
        </div>
        <div className="col-6">
          <Button size="large" className="fw-500" style={{ float: 'right' }} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
RefreshTokenModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default RefreshTokenModal;
