import App from './app/reducer';
import Auth from './auth/reducer';
import Invoice from './invoice/reducer';
import Supplier from './supplier/reducer';
import Trainer from './trainer/reducer';
import Toaster from './toaster/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
// import setting from './setting/reducer';
// import notification from './notification/reducer';

export default {
  App,
  Auth,
  Invoice,
  Supplier,
  Trainer,
  // notification,
  Toaster,
  ThemeSwitcher,
  LanguageSwitcher,
};
