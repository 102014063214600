import actions from './actions';

const initState = {
  trainers: { isFetching: false, successMsg: null, error: {}, data: [] },
  createTrainer: { isFetching: false, successMsg: null, error: {}, data: {} },
};

export default function invoiceReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TRAINERS_PENDING: {
      return {
        ...state,
        trainers: { ...state.trainers, isFetching: true, successMsg: null, error: {} },
      };
    }

    case actions.GET_TRAINERS_SUCCESS: {
      return {
        ...state,
        trainers: {
          ...state.trainers,
          isFetching: false,
          successMsg: action.payload.message,
          error: {},
          data: action.payload.data,
        },
      };
    }

    case actions.GET_TRAINERS_FAILURE: {
      return {
        ...state,
        conversion: {
          ...state.conversion,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: [],
        },
      };
    }
    // Create trainer
    case actions.CREATE_TRAINER_PENDING: {
      return {
        ...state,
        createTrainer: { ...state.createTrainer, isFetching: true, successMsg: null, error: {} },
      };
    }
    case actions.CREATE_TRAINER_SUCCESS: {
      return {
        ...state,
        createTrainer: {
          ...state.createTrainer,
          isFetching: false,
          successMsg: action.payload.message,
          error: {},
          data: action.payload.data,
        },
      };
    }
    case actions.CREATE_TRAINER_FAILURE: {
      return {
        ...state,
        createTrainer: {
          ...state.createTrainer,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: {},
        },
      };
    }
    case actions.FLUSH_CREATE_TRAINER: {
      return {
        ...state,
        createTrainer: initState.createTrainer,
      };
    }

    case actions.LOGOUT:
      //Set current user to {} which will set isAuthenticated to false
      return initState;

    default:
      return state;
  }
}
