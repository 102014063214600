export const dotenvConfig = {
  appEnv: process.env.REACT_APP_ENV || 'production',
  baseURL: process.env.REACT_APP_BACKEND_SERVER || 'http://localhost:5000/api/',
  serverURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:5000',
  pdfConversion: {
    maxFiles: parseInt(process.env.REACT_APP_PDF_CONVERSION_MAX_FILES) || 1,
    maxSizeInMB: parseInt(process.env.REACT_APP_PDF_CONVERSION_MAX_SIZE_IN_MB) || 1,
  },
  pdfTraining: {
    minFiles: parseInt(process.env.REACT_APP_PDF_TRAINING_MIN_FILES) || 1,
    // minFiles: 3,
    maxFiles: parseInt(process.env.REACT_APP_PDF_TRAINING_MAX_FILES) || 1,
    // maxFiles: 4,
    maxSizeInMB: parseInt(process.env.REACT_APP_PDF_TRAINING_MAX_SIZE_IN_MB) || 1,
  },
  pdfMapping: {
    maxFiles: parseInt(process.env.REACT_APP_PDF_MAPPING_MAX_FILES) || 1,
    maxSizeInMB: parseInt(process.env.REACT_APP_PDF_MAPPING_MAX_SIZE_IN_MB) || 1,
  },
  rejectionMailAttachments: {
    maxFiles: parseInt(process.env.REACT_APP_EMAIL_ATTACHMENT_MAX_FILES) || 1,
    maxSizeInMB: parseInt(process.env.REACT_APP_EMAIL_ATTACHMENT_MAX_SIZE_IN_MB) || 1,
  },
};
