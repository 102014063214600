import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import appActions from '../../redux/app/actions';
// import TopbarNotification from "./topbarNotification";
// import TopbarMessage from "./topbarMessage";
// import TopbarSearch from "./topbarSearch";
import TopbarUser from './topbarUser';
// import TopbarAddtoCart from "./topbarAddtoCart";
import TopbarWrapper from './topbar.style';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  render() {
    // url in props
    const { toggleCollapsed, customizedTheme, locale, user } = this.props;
    const { name = '' } = user;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}
        >
          <div className="isoLeft">
            <button
              className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="isoRight">
            <span className="fw-500 mr-1">{name}</span>

            {/* <li className="isoSearch">
              <TopbarSearch locale={locale} />
            </li> */}
            {/* <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            >
              <TopbarNotification locale={locale} />
            </li> */}
            {/* <li
              onClick={() => this.setState({ selectedItem: "message" })}
              className="isoMsg"
            >
              <TopbarMessage locale={locale} />
            </li> */}
            {/* <li onClick={() => this.setState({ selectedItem: 'addToCart' })} className="isoCart">
              <TopbarAddtoCart url={url} locale={locale} />
            </li> */}
            <li onClick={() => this.setState({ selectedItem: 'user' })} className="isoUser">
              <TopbarUser locale={locale} userName={name} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    user: state.Auth.user,
  }),
  { toggleCollapsed }
)(Topbar);
