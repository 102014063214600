import actions from './actions';

const initState = {
  Suppliers: { isFetching: false, successMsg: null, error: {}, data: [] },
  CreateSupplier: { isFetching: false, successMsg: null, error: {} },
};

export default function invoiceReducer(state = initState, action) {
  switch (action.type) {
    case actions.ADD_TRAINED_SUPPLIER_PENDING: {
      return {
        ...state,
        CreateSupplier: {
          ...state.CreateSupplier,
          isFetching: true,
          successMsg: null,
          error: {},
        },
      };
    }
    case actions.ADD_TRAINED_SUPPLIER_SUCCESS: {
      return {
        ...state,
        CreateSupplier: {
          ...state.CreateSupplier,
          isFetching: false,
          successMsg: action.payload,
          error: {},
        },
      };
    }
    case actions.ADD_TRAINED_SUPPLIER_FAILURE: {
      return {
        ...state,
        CreateSupplier: {
          ...state.CreateSupplier,
          isFetching: false,
          successMsg: null,
          error: action.payload,
        },
      };
    }
    case actions.GET_ALL_SUPPLIERS_PENDING: {
      return {
        ...state,
        Suppliers: {
          ...state.Suppliers,
          isFetching: true,
          successMsg: null,
          error: {},
          data: [],
        },
      };
    }
    case actions.GET_ALL_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        Suppliers: {
          ...state.Suppliers,
          isFetching: false,
          successMsg: null,
          error: {},
          data: action.payload,
        },
      };
    }
    case actions.GET_ALL_SUPPLIERS_FAILURE: {
      return {
        ...state,
        Suppliers: {
          ...state.Suppliers,
          isFetching: false,
          successMsg: null,
          error: action.payload,
          data: [],
        },
      };
    }
    // Delete supplier
    case actions.DELETE_SUPPLIER_SUCCESS: {
      const updatedList = state?.Suppliers?.data.filter((s) => s.id !== action.payload.id);
      return {
        ...state,
        Suppliers: {
          ...state.Suppliers,
          data: updatedList,
        },
      };
    }

    case actions.LOGOUT:
      //Set current user to {} which will set isAuthenticated to false
      return initState;

    default:
      return state;
  }
}
