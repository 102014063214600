import { axios, getAxiosError } from '../apiService';

const actions = {
  // PDF Conversion
  PDF_CONVERSON_PENDING: 'PDF_CONVERSON_PENDING',
  PDF_CONVERSON_SUCCESS: 'PDF_CONVERSON_SUCCESS',
  PDF_CONVERSON_FAILURE: 'PDF_CONVERSON_FAILURE',
  // Trainng FORM
  TRAINING_FORM_RESET: 'TRAINING_FORM_RESET',
  TRAINING_FORM_SET_ACTIVE_STEP: 'TRAINING_FORM_SET_ACTIVE_STEP',
  TRAINING_FORM_SET_TRAINING_FILES: 'TRAINING_FORM_SET_TRAINING_FILES',
  TRAINING_FORM_SET_MAPPING_FILES: 'TRAINING_FORM_SET_MAPPING_FILES',
  TRAINING_FORM_SET_EMAIL_ADDR: 'TRAINING_FORM_SET_EMAIL_ADDR',
  // PDF Training
  PDF_TRAINING_PENDING: 'PDF_TRAINING_PENDING',
  PDF_TRAINING_SUCCESS: 'PDF_TRAINING_SUCCESS',
  PDF_TRAINING_FAILURE: 'PDF_TRAINING_FAILURE',

  // Get training requests
  GET_TRAINING_REQUESTS_PENDING: 'GET_TRAINING_REQUESTS_PENDING',
  GET_TRAINING_REQUESTS_SUCCESS: 'GET_TRAINING_REQUESTS_SUCCESS',
  GET_TRAINING_REQUESTS_FAILURE: 'GET_TRAINING_REQUESTS_FAILURE',

  // Get training requests
  GET_SINGLE_TRAINING_REQUEST_PENDING: 'GET_SINGLE_TRAINING_REQUEST_PENDING',
  GET_SINGLE_TRAINING_REQUESTS_SUCCESS: 'GET_SINGLE_TRAINING_REQUESTS_SUCCESS',
  GET_SINGLE_TRAINING_REQUESTS_FAILURE: 'GET_SINGLE_TRAINING_REQUESTS_FAILURE',

  // Assign Training request
  ASSIGN_TRAINING_REQUEST_PENDING: 'ASSIGN_TRAINING_REQUEST_PENDING',
  ASSIGN_TRAINING_REQUESTS_SUCCESS: 'ASSIGN_TRAINING_REQUESTS_SUCCESS',
  ASSIGN_TRAINING_REQUESTS_FAILURE: 'ASSIGN_TRAINING_REQUESTS_FAILURE',

  // Accept Training request
  ACCEPT_TRAINING_REQUEST_PENDING: 'ACCEPT_TRAINING_REQUEST_PENDING',
  ACCEPT_TRAINING_REQUESTS_SUCCESS: 'ACCEPT_TRAINING_REQUESTS_SUCCESS',
  ACCEPT_TRAINING_REQUESTS_FAILURE: 'ACCEPT_TRAINING_REQUESTS_FAILURE',

  // Reject Training request
  REJECT_TRAINING_REQUEST_PENDING: 'REJECT_TRAINING_REQUEST_PENDING',
  REJECT_TRAINING_REQUESTS_SUCCESS: 'REJECT_TRAINING_REQUESTS_SUCCESS',
  REJECT_TRAINING_REQUESTS_FAILURE: 'REJECT_TRAINING_REQUESTS_FAILURE',
  // Flush
  FLUSH_TRAINING_REQUEST: 'FLUSH_TRAINING_REQUEST',

  createPdfConversionRequest: (values) => async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('email', values.email);
      formData.append('file', values.file[0]);
      const config = { headers: { 'content-type': 'multipart/form-data' } };
      dispatch({ type: actions.PDF_CONVERSON_PENDING });
      const res = await axios.post(`invoice/conversion-request`, formData, config);
      dispatch({ type: actions.PDF_CONVERSON_SUCCESS, payload: res.data?.message });
    } catch (error) {
      dispatch({
        type: actions.PDF_CONVERSON_FAILURE,
        payload: getAxiosError(error),
      });
    }
  },
  // Training Form
  resetTrainingForm: () => async (dispatch) => {
    dispatch({ type: actions.TRAINING_FORM_RESET });
  },
  setActiveStep: (step) => async (dispatch) => {
    dispatch({ type: actions.TRAINING_FORM_SET_ACTIVE_STEP, payload: step });
  },
  setTrainingFiles: (files) => async (dispatch) => {
    dispatch({ type: actions.TRAINING_FORM_SET_TRAINING_FILES, payload: files });
  },
  setMappingFiles: (files) => async (dispatch) => {
    dispatch({ type: actions.TRAINING_FORM_SET_MAPPING_FILES, payload: files });
  },
  setEmailAddress: (email) => async (dispatch) => {
    dispatch({ type: actions.TRAINING_FORM_SET_EMAIL_ADDR, payload: email });
  },
  pdfTraining: () => async (dispatch, getState) => {
    try {
      const {
        Invoice: { trainingForm },
      } = getState();
      const formData = new FormData();
      trainingForm.trainingFiles.forEach((file) => formData.append('trainingFiles', file));
      trainingForm.mappingFiles.forEach((file) => formData.append('mappingFiles', file));
      formData.append('email', trainingForm.email);
      const config = { headers: { 'content-type': 'multipart/form-data' } };
      dispatch({ type: actions.PDF_TRAINING_PENDING });
      const res = await axios.post(`invoice/training-request`, formData, config);
      dispatch({ type: actions.PDF_TRAINING_SUCCESS, payload: res.data?.message });
    } catch (error) {
      dispatch({
        type: actions.PDF_TRAINING_FAILURE,
        payload: getAxiosError(error),
        details: error?.response?.data?.details,
      });
    }
  },

  getTrainingRequests:
    (status = null) =>
    async (dispatch) => {
      try {
        dispatch({ type: actions.GET_TRAINING_REQUESTS_PENDING });
        const res = await axios.get(`invoice/training-request${status ? `?status=${status}` : ''}`);
        dispatch({
          type: actions.GET_TRAINING_REQUESTS_SUCCESS,
          payload: { message: res?.data?.message, data: res?.data?.data },
        });
      } catch (error) {
        dispatch({
          type: actions.GET_TRAINING_REQUESTS_FAILURE,
          payload: getAxiosError(error),
        });
      }
    },

  getTrainingRequestById: (id) => async (dispatch) => {
    try {
      dispatch({ type: actions.GET_SINGLE_TRAINING_REQUEST_PENDING });
      const res = await axios.get(`invoice/training-request/single/${id}`);
      dispatch({
        type: actions.GET_SINGLE_TRAINING_REQUESTS_SUCCESS,
        payload: { message: res?.data?.message, data: res?.data?.data },
      });
    } catch (error) {
      dispatch({
        type: actions.GET_SINGLE_TRAINING_REQUESTS_FAILURE,
        payload: getAxiosError(error),
      });
    }
  },
  assignTrainingRequestToUser: (trainingRequestId) => async (dispatch) => {
    try {
      dispatch({ type: actions.ASSIGN_TRAINING_REQUEST_PENDING });
      const res = await axios.post(`invoice/training-request/assign`, { trainingRequestId });
      dispatch({
        type: actions.ASSIGN_TRAINING_REQUESTS_SUCCESS,
        payload: { message: res?.data?.message, data: res?.data?.data },
      });
    } catch (error) {
      dispatch({
        type: actions.ASSIGN_TRAINING_REQUESTS_FAILURE,
        payload: getAxiosError(error),
      });
    }
  },

  acceptTrainingRequest:
    (trainingRequestId, comment = null) =>
    async (dispatch) => {
      try {
        dispatch({ type: actions.ACCEPT_TRAINING_REQUEST_PENDING });
        const res = await axios.post(`invoice/training-request/accept`, {
          trainingRequestId,
          comment,
        });
        dispatch({
          type: actions.ACCEPT_TRAINING_REQUESTS_SUCCESS,
          payload: { message: res?.data?.message, data: res?.data?.data },
        });
      } catch (error) {
        dispatch({
          type: actions.ACCEPT_TRAINING_REQUESTS_FAILURE,
          payload: getAxiosError(error),
        });
      }
    },
  rejectTrainingRequest:
    (trainingRequestId, rejectionReason, attachments = []) =>
    async (dispatch) => {
      try {
        const formData = new FormData();
        formData.append('trainingRequestId', trainingRequestId);
        formData.append('rejectionReason', rejectionReason);
        attachments.forEach((file) => formData.append('attachments', file));
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        dispatch({ type: actions.REJECT_TRAINING_REQUEST_PENDING });
        const res = await axios.post(`invoice/training-request/reject`, formData, config);
        dispatch({
          type: actions.REJECT_TRAINING_REQUESTS_SUCCESS,
          payload: { message: res?.data?.message, data: res?.data?.data },
        });
      } catch (error) {
        dispatch({
          type: actions.REJECT_TRAINING_REQUESTS_FAILURE,
          payload: getAxiosError(error),
        });
      }
    },

  flushTrainingRequest: () => async (dispatch) => {
    dispatch({ type: actions.FLUSH_TRAINING_REQUEST });
  },
};
export default actions;
