import Axios from 'axios';
import { dotenvConfig } from '../assets/static/dotenv';
import Auth0Helper from '../helpers/auth0/index';

export const axios = Axios.create({ baseURL: dotenvConfig.baseURL });
axios.interceptors.request.use(function (config) {
  const token = Auth0Helper.getAuthToken();
  config.headers.Authorization = `${Auth0Helper.TOKEN_TYPE} ${token}`;
  return config;
});

export const getAxiosError = (error) => {
  console.log('getAxiosError', error, error.response);
  return {
    title: error?.response?.data?.title || 'Service Unavailable',
    message: error?.response?.data?.message || '',
  };
};
