import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userRoles } from '../../helpers/types';
// import { siteConfig } from "../../settings";

// import logo from '../../image/logo/n1.png';
// import logo from '../../assets/images/logo/barq.svg';
// import logosmall from '../../image/logo/n2.png';

export default ({ collapsed }) => {
  const { isTrained, role } = useSelector((state) => state.Auth.user);
  const getRedirectionUrl = () => {
    if (role === userRoles.admin) return '/dashboard/add-supplier';
    else {
      return isTrained ? '/dashboard/pdf-conversion' : '/dashboard/pdf-training';
    }
  };
  const redirectionUrl = getRedirectionUrl();

  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to={redirectionUrl}>
              {/* <i className={siteConfig.siteIcon} /> */}
              {/* <img src={logo} width="50" alt="Site Icon" /> */}
              <p className="fw-500" style={{ fontSize: 15 }}></p>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to={redirectionUrl}>
            {/* <div class="sc-logo-header logo"></div> */}
            {/* <img src={logo} width="110" alt="BARQ Logo" /> */}
            <p className="fw-500" style={{ fontSize: 15 }}>
              Invoice Converter
            </p>
          </Link>
        </h3>
      )}
    </div>
  );
};
